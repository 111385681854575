<template>
  <static-template>
    <h1 class="heading-size-3 mt-12 mb-12">{{ $t("maintenanceHeader") }}</h1>
    <div class="error-404">
      <div>
        <p>
          {{ $t("underMaintenance") }}
        </p>
      </div>
      <img src="@/assets/maintenance/undraw_add_color_re_buro.svg" alt="" />
      <v-btn color="primary" @click="back">{{ $t("back") }}</v-btn>
    </div>
  </static-template>
</template>

<script>
export default {
  name: "underMaintenance",
  data: () => ({}),
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
